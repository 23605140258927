import React, { useLayoutEffect, useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import moment from "moment"

import Layout from "components/layout"
import SEO from "components/seo"
import { AcfImage } from "components/gutenberg/acf-image"
import { CoreGroup } from "components/gutenberg/core-group"
import { CoreHeading } from "components/gutenberg/core-heading"
import { CoreList } from "components/gutenberg/core-list"
import { CoreParagraph } from "components/gutenberg/core-paragraph"
import { CoreShortcode } from "components/gutenberg/shortcode"
import { Breadcrumb } from "../components/anti/breadcrumb/breadcrumb"
import { SocialShare } from "../components/pages/others/social-share"
import { Card } from "components/anti/card/card"

import defaultImg from "assets/img/bg_default.jpg"
import { adsScheduler } from "../utils/ads-scheduler"
import {useDfpSlot} from "components/hooks/hooks";
import DFPAds from "components/ads";
import {CoreEmbed} from "components/gutenberg/core-embed";

const PostsTemplate = ({ pageContext, path, location }) => {
  // marker
  const redirectAmpHtml = process.env.GATSBY_ROOT_URL + location.pathname
  const getDiscoverPost = useStaticQuery(GET_DISCOVER_POSTS)
  const lang = pageContext.langKey

  const data =
    lang === "en"
      ? pageContext.data
      : pageContext.data.translation || pageContext.data
  const category = pageContext.data.categories.nodes[0]
  const blocks = data.blocks
  const ads = pageContext?.ads

  const discoverData = getDiscoverPost.wordPress.categories.nodes

  const { seo } = data
  const SEODefaultImg = data.featuredImage?.node?.sourceUrl
  const [discoverPostList, setDiscoverPostList] = useState([])

  useEffect(() => {
    discoverData.forEach(data => {
      if (data.slug === pageContext.slugCategory) {
        setDiscoverPostList(data?.posts?.nodes)
      }
    })
  }, [])

  const dataFilter = [
    ...discoverPostList?.map(post => {
      if (post.slug !== pageContext.data.slug) {
        return post
      }
    }),
  ]

  const postListFilter = dataFilter.filter(item => item)

  useLayoutEffect(() => {
    // ACF Cover Main conditional
    const navbar = document.querySelector(".navbar")
    if (blocks && blocks[0] && blocks[0].name !== "acf/cover-main") {
      navbar.classList.remove("transparent")
      navbar.classList.replace("dark", "light")
    }
  }, [blocks])

// useDfpSlot({
//     path: '/21835028929/smart-move-skyscraper-duti',
//     size: [160, 600],
//     id: 'div-gpt-ad-1689818297800-0',
//     mapping: [
//         [[300, 240],[160, 600]],
//         [[0, 0],[]],
//     ]
//   });

  const breadcrumbPath = `/news-events/${category.name}/Details`

  console.log("ads", ads)
  return (
    <Layout path={path} lang={lang}>
      <Helmet>
        <link
          rel="amphtml"
          href={redirectAmpHtml.replace("/news-events/", "/news-events/amp/")}
        />
        <script async="" src="https://www.instagram.com/embed.js"></script>
        <body className="bd-posts" />
      </Helmet>
      <SEO
        title={seo.title}
        desc={seo.metaDesc}
        fbImg={SEODefaultImg}
        fbTitle={seo.opengraphTitle}
        fbDesc={seo.opengraphDescription}
        url={location.href}
        twitterTitle={seo.twitterTitle}
        twitterDesc={seo.twitterDescription}
        twitterImg={SEODefaultImg}
        type="article"
        publishedDate={data.date}
        origin={location.origin}
        pathname={location.pathname}
        amp
      />

      <script type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: data.schemaMarkup?.schemaMarkup }}
      />

      <section className="pb-main pt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <Breadcrumb path={breadcrumbPath} className="text-truncate" />
              <div className="post-header">
                <p className="category caption">{category.name}</p>
                <h1 className="title">{data.title}</h1>
                <p className="date text-muted">
                  {moment(data.date).format("DD MMM YYYY")}
                </p>
                <SocialShare link={location.href} />
              </div>
              <div className="post-content">
                {blocks &&
                  blocks.map((block, i) => {
                    const adsMobile =
                      i === 1 ? (
                        <>
                          {ads?.billboardNewsEvents?.image?.sourceUrl &&
                            adsScheduler(
                              ads?.billboardNewsEvents?.scheduler
                            ) && (
                              <div className="d-md-none py-4 bg-gray">
                                <p
                                  className="text-muted mb-0 text-center"
                                  style={{ fontSize: "14px" }}
                                >
                                  Advertisement
                                </p>
                                <a
                                  className="parallax-content"
                                  href={ads?.billboardNewsEvents?.url}
                                  target={"_blank"}
                                >
                                  <div className="img-parallax container">
                                    <img
                                      src={
                                        ads?.billboardNewsEvents?.image
                                          ?.sourceUrl
                                      }
                                      alt="ads"
                                      className="img-fluid "
                                    />
                                  </div>
                                </a>
                              </div>
                            )}
                        </>
                      ) : null
                    switch (block.name) {
                      case "acf/image":
                        return (
                          <>
                            <AcfImage data={block} key={i} />
                            {adsMobile}
                          </>
                        )
                      case "core/group":
                        return (
                          <>
                            <CoreGroup data={block} key={i} />
                            {adsMobile}
                          </>
                        )
                      case "core/heading":
                        return (
                          <>
                            <CoreHeading data={block} key={i} />
                            {adsMobile}
                          </>
                        )
                      case "core/list":
                        return (
                          <>
                            <CoreList data={block} key={i} />
                            {adsMobile}
                          </>
                        )
                      case "core/paragraph":
                        return (
                          <>
                            <CoreParagraph data={block} key={i} />
                            {adsMobile}
                          </>
                        )
                      case "core/shortcode":
                        return (
                          <>
                            <CoreShortcode
                              data={block}
                              pageContext={pageContext}
                              key={i}
                            />
                            {adsMobile}
                          </>
                        )
                      case "core/html":
                        return (
                            <>
                              <CoreEmbed data={block} key={i} />
                              {adsMobile}
                            </>
                        )
                      default:
                        return (
                          <>
                            <div key={i} />
                            {adsMobile}
                          </>
                        )
                    }
                  })}
              </div>
            </div>
            <div className="col-md-2">
              <ins data-revive-zoneid="14" data-revive-id="4d3dce4db16ff385dd1c5dd5ade84a88"></ins>
              <script async src="//rv.sinarmasland.com/www/delivery/asyncjs.php"></script>
            </div>
          </div>
        </div>
      </section>
      {postListFilter.length > 0 && (
        <section className="py-main bg-light">
          <div className="container px-sm-down-0">
            <h2 className="mb-4 px-4 px-md-0">
              {lang === "en"
                ? "Discover More Articles Like This"
                : "Temukan Lebih Banyak Artikel Seperti Ini"}
            </h2>
            <div className="row">
              {postListFilter.splice(0, 3).map((post, i) => {
                const dataLang =
                  lang === "en" ? post : post?.translation || post
                const image = dataLang?.featuredImage?.node?.sourceUrl
                  ? dataLang?.featuredImage?.node?.sourceUrl
                  : defaultImg
                const label = post?.categories?.nodes[0]
                return (
                  <div className="col-md-4 col-12 mb-4" key={i}>
                    <Card
                      variant="boxless"
                      img={image}
                      label={label?.name}
                      title={dataLang?.title}
                      imgRatio="r-4-3"
                      cardBodyClassName="px-4 px-md-0 py-2"
                      link={`${lang === "en" ? "" : "/id"}/news-events/${
                        post?.categories?.nodes[0].slug
                      }/${post?.slug}`}
                    >
                      <p className="text-muted">
                        {moment(dataLang?.date).format("DD MMM YYYY")}
                      </p>
                    </Card>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      )}
    </Layout>
  )
}

export default PostsTemplate

const GET_DISCOVER_POSTS = graphql`
  query DiscoverPost {
    wordPress {
      categories(first: 100, where: { language: EN }) {
        nodes {
          slug
          posts(first: 4) {
            nodes {
              title
              slug
              categories {
                nodes {
                  name
                  slug
                  translation(language: ID) {
                    name
                    slug
                  }
                }
              }
              featuredImage {
                node {
                  sourceUrl
                  date
                }
              }
              translation(language: ID) {
                title
                slug
                featuredImage {
                  node {
                    sourceUrl
                    date
                  }
                }
              }
              schemaMarkup {
                schemaMarkup
              }
            }
          }
        }
      }
    }
  }
`
