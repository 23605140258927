import moment from "moment"

export const adsScheduler = scheduler => {
  const currentDate = moment()
  const startDate = moment(scheduler?.startDate, "DD/MM/YYYY")
  const endDate = moment(scheduler?.endDate, "DD/MM/YYYY")

  const result = currentDate.isBetween(startDate, endDate)

  return result
}
