import React from "react"
import { graphql } from "gatsby"

export const CoreEmbed = ({ data }) => {
  console.log(data)
  return (
    <div className={`core-embed`}
      dangerouslySetInnerHTML={{ __html: data.saveContent }}
    />
  )
}
